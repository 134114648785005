import { Injectable } from '@angular/core';
import {Lead} from '../models/lead';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  phoneNumber = '855-COVER-99';
  phoneNumberLink = '+18552683799';
  lead = new Lead();
  sessionId = new Subject<string>();
  clientIp = new Subject<string>();
  loading = new Subject<boolean>();
  loadingText = new Subject<string>();

  constructor() { }
}
