import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { HeaderComponent } from './shared/componets/header/header.component';
import { FooterComponent } from './shared/componets/footer/footer.component';
import { SaveLeadService } from './shared/services/save-lead.service';
import { SessionIdService } from './shared/services/session-id.service';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AppStateService } from './shared/services/app-state.service';
import {NgxMaskModule} from 'ngx-mask';
import { FormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { TermsUseComponent } from './terms-use/terms-use.component';
import { PrivacyComponent } from './privacy/privacy.component';
import {CookieService} from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    ThankYouComponent,
    HeaderComponent,
    FooterComponent,
    LandingPageComponent,
    NotFoundComponent,
    TermsUseComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    HttpClientModule,
  ],
  providers: [SaveLeadService, SessionIdService, AppStateService, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
